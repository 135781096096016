import React, { FC } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { cn } from '@/lib/classNames';
import { PublishingReview } from '@/controllers/graphql/generated';
import { section } from '@/components/ui/section';
import { typography } from '@/components/ui/typography';
import { Button } from '@/components/ui/Button';
import { IconChevronLeft } from '@/components/ui/icons/IconChevronLeft';
import { IconChevronRight } from '@/components/ui/icons/IconChevronRight';
import {
  PublishingReviewItem,
} from '@/components/homepage-landing/PublishingReviewsSection/components/PublishingReviewItem';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import { useStateRef } from '@/hooks/useStateRef';
import styles from './PublishingReviewsSection.module.scss';

interface Props {
  id?: string;
  publishingReviews: PublishingReview[];
}

export const PublishingReviewsSection: FC<Props> = ({
  id,
  publishingReviews,
}) => {
  const { t } = useTranslation([I18N_CODES.home]);

  const [prevButton, setPrevButtonRef] = useStateRef<HTMLButtonElement>();
  const [nextButton, setNextButtonRef] = useStateRef<HTMLButtonElement>();

  return (
    <section
      id={id}
      data-qa="publishing-reviews-section"
      className={cn({ [section.scrollSection]: Boolean(id) })}
    >
      <div className="overflow-hidden">
        <div className="grid-container">
          <div className="grid-x grid-margin-x mb-76 medium-mb-64 small-mb-24">
            <div className="cell large-7 medium-8 small-mb-56">
              <h2
                className={cn(typography.landingH2)}
                dangerouslySetInnerHTML={{
                  __html: t(`${I18N_CODES.home}:publishings_reviews_title`),
                }}
              />
            </div>

            <div className={cn(
              'cell large-5 medium-4 small-12 flex-container align-self-bottom align-right',
              styles.buttons,
            )}
            >
              <Button
                data-qa="left-reviews-button"
                className="mr-16"
                LeftIcon={IconChevronLeft}
                mode={Button.mode.Secondary}
                size={Button.size.Large}
                ref={setPrevButtonRef}
              />
              <Button
                data-qa="right-reviews-button"
                LeftIcon={IconChevronRight}
                mode={Button.mode.Secondary}
                size={Button.size.Large}
                ref={setNextButtonRef}
              />
            </div>
          </div>

          <Swiper
            className={cn(styles.container, 'overflow-visible')}
            modules={[Navigation]}
            navigation={{
              prevEl: prevButton,
              nextEl: nextButton,
            }}
            spaceBetween={24}
            slidesPerView={1}
            speed={500}
            grabCursor
            autoplay={{
              disableOnInteraction: true,
            }}
            breakpoints={{
              1200: {
                slidesPerView: 3,
              },
              1024: {
                slidesPerView: 2,
              },
              640: {
                slidesPerView: 1,
              },
              320: {
                slidesPerView: 1,
              },
            }}
          >
            {publishingReviews.map((review) => (
              <SwiperSlide key={review.id}>
                <PublishingReviewItem review={review} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};
