import React, { FC } from 'react';

export const Plane: FC = () => (
  <svg width="121" height="64" viewBox="0 0 121 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M100.32 11.457L33.5637 51.9683L25.5529 35.1811L100.32 11.457Z" fill="#18181A" />
    <path d="M100.32 11.457L33.5637 51.9683L25.5529 35.1811L100.32 11.457Z" stroke="#18181A" strokeWidth="0.95" strokeLinecap="round" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M85.5 19.3975L32.9856 38.7764L33.2053 52.2012L85.5 19.3975Z" fill="#E77062" />
    <path d="M85.5 19.3975L32.9856 38.7764L33.2053 52.2012L85.5 19.3975Z" stroke="#18181A" strokeWidth="0.95" strokeLinecap="round" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M58.0866 48.7121L32.9787 38.7839L113.266 7.25781L58.0866 48.7121Z" fill="#E77062" />
    <path d="M58.0866 48.7121L32.9787 38.7839L113.266 7.25781L58.0866 48.7121Z" stroke="#18181A" strokeWidth="0.95" strokeLinecap="round" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M26.231 35.448L1.09301 25.4978L113.266 7.25781L26.231 35.448Z" fill="#E77062" />
    <path d="M26.231 35.448L1.09301 25.4978L113.266 7.25781L26.231 35.448Z" stroke="#18181A" strokeWidth="0.95" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
