import {
  ConsultationRedirectLinkProps,
  ExpiredStatuses,
  RegistrationQuery, SuccessPageProps,
  TimerExpiredAtTimeOptions,
} from '@/components/consultation/consultation.typedefs';
import { DAY } from '@/constants';
import { MinimumArray } from '@/lib/helpers/utility-types';
import { checkArrayLength } from '@/lib/helpers/checkArrayLength';
import { ROUTES } from '@/controllers/router/router.contants';

export const getSuccessRedirectLink = (
  props: ConsultationRedirectLinkProps,
) => {
  const {
    courseSlug,
    shouldRedirectToTrialIntro,
    subscriptionPlanUuid,
    shouldRedirectToIntroductionTopic,
    moduleSlug,
    topicSlug,
    redirectUrl,
  } = props;

  if (redirectUrl) {
    return redirectUrl;
  }

  if (subscriptionPlanUuid) {
    return `${ROUTES.subscription.subscriptionPay}/${subscriptionPlanUuid}`;
  }

  if (shouldRedirectToTrialIntro) {
    return `${ROUTES.user.learnTopic({
      moduleSlug: 'test-drive',
      topicSlug: 'welcome-to-test-drive',
    })}`;
  }

  if (
    shouldRedirectToIntroductionTopic
    && moduleSlug
    && topicSlug
  ) {
    return `${ROUTES.user.learnTopic({
      moduleSlug,
      topicSlug,
    })}`;
  }

  if (courseSlug) {
    return `${ROUTES.user.learnCourse(courseSlug)}`;
  }

  return `${ROUTES.user.learn}`;
};

export const getNextExpirationTime = (
  startedAtTime: number,
  repeatDaysSteps: MinimumArray<1, number>,
) => {
  const currentTime = Date.now();

  // Calculate the total elapsed time in days since the start
  const elapsedTimeInDays = Math.floor((currentTime - startedAtTime) / DAY);

  // Sum the intervals and determine the next interval based on the start time
  let totalDays = 0;
  let intervalIndex = 0;

  while (totalDays <= elapsedTimeInDays) {
    const currentInterval = (
      repeatDaysSteps[intervalIndex]
      || repeatDaysSteps[0]
    );

    totalDays += currentInterval;

    intervalIndex = (intervalIndex + 1) % repeatDaysSteps.length;
  }

  // Calculate the next expiration time based on the startedAtTime
  return startedAtTime + totalDays * DAY;
};

export const successPageData = (props: SuccessPageProps) => {
  const {
    courseSlug,
    shouldRedirectToTrialIntro,
    shouldRedirectToIntroductionTopic,
    moduleSlug,
    topicSlug,
    redirectUrl,
  } = props;

  return ({
    redirectLink: getSuccessRedirectLink({
      courseSlug,
      shouldRedirectToTrialIntro,
      shouldRedirectToIntroductionTopic,
      moduleSlug,
      topicSlug,
      redirectUrl,
    }),
    text: 'success_page_pt_text',
    buttonName: 'success_page_pt_submit',
  });
};

export const buildQuery = (query: RegistrationQuery) => {
  const queryString = Object.entries(query)
    .map(([key, value]) => (
      `${key}=${value}`
    ))
    .join('&');

  return queryString.length > 0
    ? `?${queryString}`
    : '';
};

export const getTimerExpiredAtTime = ({
  promoCode,
  promoFirstInteractionTime,
}: TimerExpiredAtTimeOptions): number => {
  const {
    status,
    startedAt,
    expiredAt,
    timerConfig,
  } = promoCode;

  if (ExpiredStatuses.includes(status)
    || (expiredAt && Number(expiredAt) <= Date.now())
  ) {
    return 0;
  }

  if (expiredAt && !timerConfig?.repeatDaysSteps) {
    return Number(expiredAt);
  }

  const startedAtTime = timerConfig?.useFirstInteractionTime
    ? promoFirstInteractionTime
    : Number(startedAt);

  const repeatDaysStepsFromTimerConfig: number[] = timerConfig?.repeatDaysSteps
    ? timerConfig.repeatDaysSteps
    : [];

  const repeatDaysSteps: MinimumArray<1, number> = (
    checkArrayLength(repeatDaysStepsFromTimerConfig, 1)
      ? repeatDaysStepsFromTimerConfig
      : [timerConfig?.repeatDaysStep || 3]
  );

  const nextExpirationTime = getNextExpirationTime(
    startedAtTime,
    repeatDaysSteps,
  );

  if (expiredAt && nextExpirationTime > Number(expiredAt)) {
    return Number(expiredAt);
  }

  return nextExpirationTime;
};
