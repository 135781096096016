import React, { FC } from 'react';

import { cn } from '@/lib/classNames';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import { IconQuote } from '@/components/ui/icons/IconQuote';
import { typography } from '@/components/ui/typography';
import styles from '@/components/landing/Quote/Quote.module.scss';
import { CeoBlock } from '@/components/landing/CeoBlock';
import { LeftLine } from '@/components/landing/Quote/static/LeftLine';
import { RightLine } from '@/components/landing/Quote/static/RightLine';
import { Plane } from '@/components/landing/Quote/static/Plane';

export const Quote: FC = () => {
  const { t } = useTranslation([I18N_CODES.quote]);

  return (
    <div className={styles.wrapper}>
      <blockquote className="grid-container">
        <div className="grid-x grid-margin-x">
          <div className={cn(styles.container, 'cell large-6 large-offset-3')}>
            <div className={styles.decorations}>
              <div className={styles.decoration}>
                <LeftLine />
              </div>

              <div className={styles.decoration}>
                <RightLine />
              </div>

              <div className={styles.decoration}>
                <Plane />
              </div>
            </div>

            <div className="mb-24">
              <IconQuote className={styles.icon} />
            </div>

            <p
              className={cn(styles.quote, typography.withGrayAccentText, 'mb-64 small-mb-56')}
              dangerouslySetInnerHTML={{
                __html: t(`${I18N_CODES.quote}:quote_main_text`),
              }}
            />

            <CeoBlock />
          </div>
        </div>
      </blockquote>
    </div>
  );
};
