import React, { FC, useCallback } from 'react';
import { cn } from '@/lib/classNames';
import { PublishingReview } from '@/controllers/graphql/generated';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { LandingEvents } from '@/controllers/landing/landing.events';
import { Image } from '@/components/ui/Image';
import { typography } from '@/components/ui/typography';
import { Button } from '@/components/ui/Button';
import { IconExternalLink } from '@/components/ui/icons/IconExternalLink';
import styles from './PublishingReviewItem.module.scss';

interface Props {
  review: PublishingReview;
}

export const PublishingReviewItem: FC<Props> = ({ review }) => {
  const {
    logo,
    publishingName,
    link: originalSourceLink,
  } = review;

  const { t } = useTranslation([I18N_CODES.reviews]);

  const sendSourceLinkEvent = useCallback(() => (
    LandingEvents.sendEvent(
      LandingEvents.events.publishingReviewButtonClicked,
      {
        publishingCompanyName: publishingName,
      },
    )
  ), [publishingName]);

  return (
    <article data-qa='publishing-review' className={styles.item}>
      <div data-qa="publishing-reviews-header" className="flex-container align-middle mb-24">
        <div className={cn(styles.imageWrapper, 'mr-24')}>
          <Image
            src={logo?.url ?? ''}
            alt={publishingName}
            className={styles.companyLogo}
            width={64}
            height={64}
            objectFit="cover"
            data-qa="image-publishing-reviews"
          />
        </div>

        <p className={typography.landingH5}>
          {t(`${I18N_CODES.reviews}:publishing_review_title_${publishingName}`)}
        </p>
      </div>

      <p
        data-qa="description-publishing-reviews"
        className={cn(
          styles.article,
          typography.paragraph,
          typography.landingTextMain,
        )}
      >
        {t(`${I18N_CODES.reviews}:publishing_review_${publishingName}`)}
      </p>

      <div className={styles.filler} />

      {originalSourceLink && (
        <Button
          data-qa="read-origin-publishing-reviews-button"
          size={Button.size.Large}
          mode={Button.mode.Secondary}
          text={t(`${I18N_CODES.reviews}:reviews_read_origin_button`)}
          RightIcon={IconExternalLink}
          href={originalSourceLink}
          onClick={sendSourceLinkEvent}
          target="_blank"
          rel="nofollow noreferrer noopener"
          className="mt-24"
          hasFullWidth
        />
      )}
    </article>
  );
};
