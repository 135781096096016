import {
  StaticFilesQueryResult,
  useStaticFilesQuery,
} from '@/controllers/staticFiles/graphql/generated/staticFiles.query.generated';
import { StaticFileFragment } from '@/controllers/staticFiles/graphql/generated/StaticFile.fragment.generated';
import { FileBaseFragment } from '@/controllers/files/File/generated/FileBase.fragment.generated';

type UseStaticFilesOutput = [
  Record<string, FileBaseFragment | null>,
  StaticFilesQueryResult
];

const parseStaticFiles = (
  staticFiles: StaticFileFragment[],
) => staticFiles.reduce((acc: Record<string, FileBaseFragment | null>, cur) => {
  Object.assign(acc, { [cur.name ?? 'unknown']: cur.image });

  return acc;
}, {});

/** @deprecated it loads all static files on every page where called, don't use it */
export const useStaticFilesDeprecated = (): UseStaticFilesOutput => {
  const queryResult = useStaticFilesQuery();

  const { data } = queryResult;

  const content = parseStaticFiles(data?.allStaticFiles || []);

  return [content, queryResult];
};
