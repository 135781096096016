import React from 'react';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { FCIcon } from '@/components/ui/icons/typedefs';

export const IconQuote: FCIcon = (props) => (
  <BaseIcon
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M22.3993 3.00473L21.8883 6.80073C20.7689 6.7034 19.8808 6.89806 19.2238 7.38473C18.5668 7.84706 18.1166 8.51623 17.8733 9.39223C17.6543 10.2439 17.6056 11.2172 17.7273 12.3122H22.3993V21.6927H13.3838V11.9472C13.3838 8.75956 14.1381 6.35056 15.6468 4.72023C17.1554 3.0899 19.4063 2.51806 22.3993 3.00473ZM10.1718 3.00473L9.66075 6.80073C8.54142 6.7034 7.65325 6.89806 6.99625 7.38473C6.33925 7.84706 5.88908 8.51623 5.64575 9.39223C5.42675 10.2439 5.37808 11.2172 5.49975 12.3122H10.1718V21.6927H1.15625V11.9472C1.15625 8.75956 1.91058 6.35056 3.41925 4.72023C4.92792 3.0899 7.17875 2.51806 10.1718 3.00473Z" fill="currentColor" />
  </BaseIcon>
);
