import { sendAmplitudeData } from '@/controllers/amplitude/amplitude.helpers';
import { GoogleAnalyticsClient } from '@/controllers/analytics/analytics.client/GoogleAnalytics.client';

export const LandingEvents = {
  sendEvent: (event: string, data: Record<string, any> = {}) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { gtm, ...rest } = data;

    const eventName = `landing_${event}`;

    sendAmplitudeData(eventName, rest);
    GoogleAnalyticsClient.getInstance().sendEvent(eventName, data);
  },
  events: {
    socialLoginButtonClicked: 'social_login_button_clicked',
    webinarTelegramButtonClicked: 'webinar_telegram_button_clicked',
    publishingReviewButtonClicked: 'publishing_review_button_clicked',
    platformDemoCTAButtonClicked: 'platform_demo_cta_button_clicked',
  },
};
