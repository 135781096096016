import React from 'react';
import { cn } from '@/lib/classNames';
import { Image } from '@/components/ui/Image';
import { typography } from '@/components/ui/typography';
import { useStaticFilesDeprecated } from '@/controllers/staticFiles/staticFiles.hooks/useStaticFilesDeprecated';
import { STATIC_IMAGES_DEPRECATED } from '@/controllers/staticFiles/staticFiles.constants';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import styles from './CeoBlock.module.scss';

export const CeoBlock = () => {
  const [staticFiles] = useStaticFilesDeprecated();

  const { t } = useTranslation([I18N_CODES.quote]);

  return (
    <div className={styles.wrapper}>
      <div className={cn(styles.imageWrapper, 'mr-24')}>
        <Image
          src={staticFiles[STATIC_IMAGES_DEPRECATED.ceo_avatar]?.url ?? ''}
          alt={t(`${I18N_CODES.quote}:alt_quote_author`)}
          className={styles.image}
          width={72}
          height={72}
          objectFit="cover"
        />
      </div>

      <div>
        <h5 className={cn(typography.landingH5, 'mb-4')}>
          {t(`${I18N_CODES.quote}:quote_author_name`)}
        </h5>

        <p className={cn(typography.landingTextMain, styles.text)}>
          {t(`${I18N_CODES.quote}:quote_author_description`)}
        </p>
      </div>
    </div>
  );
};
