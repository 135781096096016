import React, { memo } from 'react';
import { useShouldShowOnlyPrepaidCourses } from '@/components/hooks/useShouldShowOnlyPrepaidCourses.hook';
import { useShouldShowProfessions } from '@/components/hooks/useShouldShowProfessions.hook';
import { EmploymentGuaranteePostpaid } from './EmploymentGuaranteePostpaid';
import { EmploymentGuaranteePrepaid } from './EmploymentGuaranteePrepaid';
import { EmploymentGuaranteeMergedLandings } from './EmploymentGuaranteeMergedLandings';
import { COURSE_BLOCKS } from '../CoursePage/CoursePageContent.constants';

export const EmploymentGuaranteeSection = memo(() => {
  const shouldShowOnlyPrepaidCourses = useShouldShowOnlyPrepaidCourses();
  const shouldShowProfessions = useShouldShowProfessions();

  if (shouldShowOnlyPrepaidCourses) {
    return (
      <EmploymentGuaranteePrepaid
        id={COURSE_BLOCKS.employmentGuarantee}
      />
    );
  }

  if (shouldShowProfessions) {
    return (
      <EmploymentGuaranteeMergedLandings
        id={COURSE_BLOCKS.employmentGuarantee}
      />
    );
  }

  return (
    <EmploymentGuaranteePostpaid
      id={COURSE_BLOCKS.employmentGuarantee}
    />
  );
});
