import { PromoCodeStatus } from '@/controllers/graphql/generated';
import { PromoCodeLandingFragment } from '@/controllers/promoCode/generated/promoCodeLanding.fragment.generated';

export interface RegistrationQuery {
  /** @deprecated use snake_case */
  courseSlug?: string;
  /** @deprecated use snake_case */
  redirectTo?: string;
  /** @deprecated use snake_case */
  studentSource?: string;
  /** @deprecated use snake_case */
  selectedSlug?: string;
  /** @deprecated use snake_case */
  promoCode?: string;

  course_slug?: string;
  success_redirect_url?: string;
  promo_code?: string;
  redirect_to?: string;
  student_source?: string;
  selected_slug?: string;
  source?: string;
}

export const ExpiredStatuses = [
  PromoCodeStatus.Expired,
  PromoCodeStatus.Inactive,
];

export interface SuccessPageProps {
  courseSlug?: string;
  shouldRedirectToTrialIntro?: boolean;
  shouldRedirectToIntroductionTopic?: boolean;
  moduleSlug?: string;
  topicSlug?: string;
  redirectUrl?: string;
}

export interface TimerExpiredAtTimeOptions {
  promoCode: PromoCodeLandingFragment;
  promoFirstInteractionTime: number;
}

export interface ConsultationRedirectLinkProps {
  courseSlug?: string;
  shouldRedirectToTrialIntro?: boolean;
  subscriptionPlanUuid?: string;
  shouldRedirectToIntroductionTopic?: boolean;
  moduleSlug?: string;
  topicSlug?: string;
  redirectUrl?: string;
}
