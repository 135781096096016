import { SECOND } from '@/constants';
import { initSessionStorage } from '@/lib/browserStorage';
import { SESSION_STORAGE_KEYS } from '@/constants/sessionStorage';

export const DELAY = {
  POPUP: 10 * SECOND,
  INACTIVE: 30 * SECOND,
};

export const initExitPopupOpenedStorage = (pathname: string) => (
  initSessionStorage({
    key: SESSION_STORAGE_KEYS.exitPopupOpened(pathname),
    initialValue: false,
  })
);
