/** @deprecated don't use static files, it loads all files on all pages where query is called */
export const STATIC_IMAGES_DEPRECATED = {
  coverTeam: 'cover_team',
  ceo_avatar: 'ceo_avatar',
  mate_avatar: 'mate_avatar',

  hiresLinkedinSettings: 'hires_linkedin_settings',
  hiresLinkedinPrivacyToggles: 'hires_linkedin_privacy_toggles',
  platformLogoCurrentThing: 'platform_logo_current_thing',
};
